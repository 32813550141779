function tourneesController($translate, $state, $scope, WS, $window, $sce) {
    'ngInject';
    const vm = this;
 
    vm.filter=  {};

    vm.days = [
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
        'Dimanche'
    ];
    
    vm.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };


    vm.getRouteWithDepot = function () {
        WS.get('routing/getRouteWithDepot')
            .then(function (response) {
                vm.routeswithdepot = response.data;
                $scope.$apply();
                /*$('.table_routes').DataTable({
                        "language": DatatableLang
                    });*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.findTournees = function () {
         
        console.log(vm.filter)
        WS.post('tournees/findTournees', vm.filter).then(
            function (response) {
 
                if(vm.filter.commercial_codes.length>0){
                    vm.list_users = vm.selectusers.filter(user => vm.filter.commercial_codes.includes(user.code))
                }else{
                    vm.list_users = vm.selectusers;
                }
                vm.all_days = response.data.all_days;

                vm.tournees = response.data.tournees;
                vm.visites = response.data.visites;

                console.log(vm.all_days, vm.list_users)


                /**
                 * start traitement trie date
                 */
                if(vm.filter.trie=="date"){
                    var trs = ``;
                    _.each(vm.all_days, (day, indexDay) => {
                        _.each(vm.list_users, (user, indexUser) => {

                            var keyTourneD = day.date+'_'+user.depot_code;
                            var keyTourneU = day.date+'_'+user.code;
                            var tournee = vm.tournees[keyTourneD] ? vm.tournees[keyTourneD] : [];

                            //frequence
                            var frequences = _.map(tournee, 'frequence');
                                frequences = [...new Set(frequences)]; // Remove duplicates

                            //codes tournee
                            var codesT = _.map(tournee, 'code');
                                codesT = [...new Set(codesT)]; // Remove duplicates

                            //client codes
                            var client_codesTournee = _.map(tournee, 'client_code');
                            client_codesTournee = [...new Set(client_codesTournee)]; // Remove duplicates

                            /**
                             * Visites , BLS, AVOIR, COMMANDE
                             */
                            var visitesDay = vm.visites[keyTourneU] ? vm.visites[keyTourneU] : [];

                            var all_types = _.pluck(visitesDay, 'types');
                            // Flatten the array by splitting each string into individual elements
                            all_types = _.flatten(_.map(all_types, function(item) {
                                return item.split(', ');
                            }));

                            // Count the occurrences of 'bl' and 'commande'
                            var countBl = _.filter(all_types, function(item) {
                                return item === 'bl';
                            }).length;

                            var countCommande = _.filter(all_types, function(item) {
                                return item === 'commande';
                            }).length;

                            var countAvoir = _.filter(all_types, function(item) {
                                return item === 'avoir';
                            }).length;

                            /**
                             * PLUCK CLIENT_CODE FROM VISITES
                             */

                            var CLS_VISITES = _.pluck(visitesDay, 'client_code');
                            var v_cls_in_tournee = 0;
                            var v_cls_out_tournee= 0;
                            _.each(CLS_VISITES, (client_code) => {
                                if(_.contains(client_codesTournee, client_code)){
                                    v_cls_in_tournee++;
                                }else{
                                    v_cls_out_tournee++;
                                }
                            })

                            var taux_succes_tournee = client_codesTournee.length > 0 ? v_cls_in_tournee * 100 / client_codesTournee.length : 0
                            taux_succes_tournee = taux_succes_tournee.toFixed(2);

                            var taux_succes_visites = client_codesTournee.length > 0 ? visitesDay.length * 100 / client_codesTournee.length : 0
                            taux_succes_visites = taux_succes_visites.toFixed(2);
                            trs += `<tr>
                                <td class="text-center">${day.date}</td>
                                <td class="text-center">${day.day_name}</td>
                                <td class="text-center">${ frequences.length>0 ? frequences.join(", ") : '' }</td>
                                <td class="text-center">${user.user}</td>
                                <td class="text-center">${ codesT.length>0 ? codesT.join(", ") : '' }</td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center">${tournee.length }</td>
                                <td class="text-center">${v_cls_in_tournee}</td>
                                <td class="text-center">${v_cls_out_tournee}</td>
                                <td class="text-center"></td>
                                <td class="text-center">${vm.visites[keyTourneU] ? vm.visites[keyTourneU].length : ''}</td>
                                <td class="text-center">${countBl}</td>
                                <td class="text-center">${countAvoir}</td>
                                <td class="text-center">${countCommande}</td>
                                <td class="text-center">${taux_succes_tournee} %</td>
                                <td class="text-center">${taux_succes_visites} %</td>
                            </tr>`;
                        })
                    })
                }


                /**
                 * start traitement trie commercial
                 */
                if(vm.filter.trie=="commercial"){
                    var trs = ``;
                    _.each(vm.list_users, (user, indexUser) => {
                        _.each(vm.all_days, (day, indexDay)  => {

                            var keyTourneD = day.date+'_'+user.depot_code;
                            var keyTourneU = day.date+'_'+user.code;
                            var tournee = vm.tournees[keyTourneD] ? vm.tournees[keyTourneD] : [];

                             //frequence
                             var frequences = _.map(tournee, 'frequence');
                             frequences = [...new Set(frequences)]; // Remove duplicates

                            //codes tournee
                            var codesT = _.map(tournee, 'code');
                             codesT = [...new Set(codesT)]; // Remove duplicates

                            //client codes
                            var client_codesTournee = _.map(tournee, 'client_code');
                            client_codesTournee = [...new Set(client_codesTournee)]; // Remove duplicates

                            /**
                             * Visites , BLS, AVOIR, COMMANDE
                             */
                            var visitesDay = vm.visites[keyTourneU] ? vm.visites[keyTourneU] : [];

                            var all_types = _.pluck(visitesDay, 'types');
                            // Flatten the array by splitting each string into individual elements
                            all_types = _.flatten(_.map(all_types, function(item) {
                                return item.split(', ');
                            }));

                            // Count the occurrences of 'bl' and 'commande'
                            var countBl = _.filter(all_types, function(item) {
                                return item === 'bl';
                            }).length;

                            var countCommande = _.filter(all_types, function(item) {
                                return item === 'commande';
                            }).length;

                            var countAvoir = _.filter(all_types, function(item) {
                                return item === 'avoir';
                            }).length;

                            /**
                             * PLUCK CLIENT_CODE FROM VISITES
                             */

                            var CLS_VISITES = _.pluck(visitesDay, 'client_code');
                            var v_cls_in_tournee = 0;
                            var v_cls_out_tournee= 0;
                            _.each(CLS_VISITES, (client_code) => {
                                if(_.contains(client_codesTournee, client_code)){
                                    v_cls_in_tournee++;
                                }else{
                                    v_cls_out_tournee++;
                                }
                            })

                            var taux_succes_tournee = client_codesTournee.length > 0 ? v_cls_in_tournee * 100 / client_codesTournee.length : 0
                            taux_succes_tournee = taux_succes_tournee.toFixed(2);

                            var taux_succes_visites = client_codesTournee.length > 0 ? visitesDay.length * 100 / client_codesTournee.length : 0
                            taux_succes_visites = taux_succes_visites.toFixed(2);

                            trs += `<tr>
                                <td class="text-center">${day.date}</td>
                                <td class="text-center">${day.day_name}</td>
                                <td class="text-center">${ frequences.length>0 ? frequences.join(", ") : '' }</td>
                                <td class="text-center">${user.user}</td>
                                <td class="text-center">${ codesT.length>0 ? codesT.join(", ") : '' }</td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center">${tournee.length }</td>
                                <td class="text-center">${v_cls_in_tournee}</td>
                                <td class="text-center">${v_cls_out_tournee}</td>
                                <td class="text-center"></td>
                                <td class="text-center">${vm.visites[keyTourneU] ? vm.visites[keyTourneU].length : ''}</td>
                                <td class="text-center">${countBl}</td>
                                <td class="text-center">${countAvoir}</td>
                                <td class="text-center">${countCommande}</td>
                                <td class="text-center">${taux_succes_tournee} %</td>
                                <td class="text-center">${taux_succes_visites} %</td>
                        </tr>`;
                        })
                    })
                }


                vm.tbodys = vm.trustHtml(trs);

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    }

}
export default {
    name: 'tourneesController',
    fn: tourneesController
};